<template>
  <ul
    class="h-selector"
    aria-label="theme"
  >
    <template
      v-for="(item, index) in items"
      :key="item.value"
    >
      <li
        v-if="selectedItem"
        :class="['h-selector-item', { 'delimiter': index !== 0 }]"
      >
        <button
          :aria-label="item.keyName"
          :class="{ 'selected': selectedItem.key === item.key }"
          :aria-pressed="selectedItem.key === item.key || null"
          tabindex="0"
          @click="itemClicked(item)"
        >
          <span :class="`ts-s-icon s-${getIconName(item.keyName, selectedItem.key === item.key)}`"></span>
          <span class="theme-indicator-text">{{ item.value }}</span>
        </button>
      </li>
    </template>
  </ul>
</template>

<script>

export default {
  name: "ThemesSelector",
  model: {
    prop: 'selectedItem',
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedItem: {
      type: Object,
      default: () => {},
    }
  },
  emits: ['input'],
  data() {
    return {}
  },
  computed: {},
  created() {

  },
  methods: {
    getIconName(themeName, selected) {
      const icons = {
        dark: 'moon',
        light: 'sun'
      }
      return selected ? `${icons[themeName]}-selected` : icons[themeName];
    },
    itemClicked(item) {
      this.$emit('input', item);
    },
  }
}

</script>

<style lang='scss' scoped>
@import "../../../../assets/scss/_mixins";
@import "../../../../assets/scss/global";

.ts-s-icon {
  display: block;
  width: 22px;
  height: 22px;
}

$icons: s-moon, s-moon-selected, s-sun, s-sun-selected;
@include add-icons-from-list($icons, 0, 0, false);

.h-selector {
  height: 72px;
  width: 100%;
  display: flex;
  gap: 10px;

  .h-selector-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;


    button.selected {

      &:focus {
        @include themify(null, 'show-a11y-focus-outline') {
          box-shadow: 0 0 0 4px getColor('user-menu-status-item-box-shadow-selected', 'framework') !important;
        }
      }

    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 24px;
      padding: 10px 16px;
      width: 100%;
      height: 40px;

      gap: 8px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;

      background: transparent;
      border-color: transparent;

      &:hover {
        @include themify {
          background-color: getColor('user-menu-item-hover', 'framework');
        }
      }

      &:focus {
        @include themify(null, 'show-a11y-focus-outline') {
          box-shadow: 0 0 0 4px getColor('user-menu-status-item-box-shadow', 'framework') !important;
        }
      }

      .theme-indicator-text {
        line-height: 25px;
        text-transform: capitalize;
        text-shadow: none;
      }

      @include themify {
        color: getColor('user-menu-text', 'framework');
      }

      &.selected {
        @include themify {
          color: getColor('user-menu-status-item-selected-color', 'framework');
          background-color: getColor('user-menu-status-item-selected-bg', 'framework');
        }
      }

    }
  }
}
</style>
