<template>
  <div>
    <div class="top-level-menu-status-list"
         role="group"
         :aria-label="$translator.translate('LEWebAgent.agentStatus')"
    >
      <top-level-menu-status-item-view v-for="statusItem in statusItems"
                                       :statusItem="statusItem"
                                       :selectedItem="selectedItem"
                                       :selectedTheme="selectedTheme"
                                       :isFirstStatusAfterLogin="isFirstStatusAfterLogin"
                                       :hasConfigForCustomMaxSize="hasConfigForCustomMaxSize"
                                       :expanded="customReasonsOpen ? 'true' : 'false'"
                                       :canExpand="canExpand(statusItem)"
                                       v-bind:key="statusItem.value"
                                       @clicked="itemClicked"
                                       @setFirstStatusToFalse="setFirstStatusToFalse">

      </top-level-menu-status-item-view>
    </div>
    <div v-bind:class="customReasonsClass">
      <div class="agent-statuses-list-custom-reasons-search-container">
        <div class="agent-statuses-list-custom-reasons-search-title-container">
          <span data-lp-at="search-away-reasons-title">{{ $translator.translate('LEFramework.usermenu.search.away.reasons.title') }}</span>
        </div>
        <div class="agent-statuses-list-custom-reasons-search-input-container">
          <input data-lp-at="search-away-reasons-input" type="text" v-model="searchValue" :placeholder="$translator.translate('LEFramework.usermenu.search.away.reasons.input.placeholder')" />
        </div>
      </div>
      <div class="agent-statuses-list-custom-reasons-container">
        <top-level-menu-custom-reason-item-view v-for="customReason in filteredAwayStatusesList()"
                                                :customReason="customReason"
                                                :data-lp-at="`custom-reason-element-` + customReason.id"
                                                :isChosen="itemChosen && itemChosen.id == customReason.id"
                                                :key="customReason.id"
                                                :class="itemChosen && itemChosen.id == customReason.id ? 'highlight-chosen' : ''"
                                                @clicked="chooseItem">
        </top-level-menu-custom-reason-item-view>
      </div>
      <div class="agent-statuses-list-custom-reasons-confirmation-container">
        <div class="agent-statuses-list-custom-reasons-confirmation-buttons-container">
          <button class="agent-statuses-list-custom-reasons-confirmation-buttons-cancel-button" data-lp-at="custom-away-reason-cancel" @click="cancelButtonCustomReasons">{{$translator.translate('LEFramework.usermenu.search.away.reasons.button.cancel')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as CONST from 'const';
import TopLevelMenuStatusItemView from './TopLevelMenuStatusItemView';
import TopLevelMenuCustomReasonItemView from './TopLevelMenuCustomReasonItemView';
import { leDialog } from '@liveperson/lp-vue-components';

export default {
  name: "top-level-menu-status-list",
  props: {
    selectedTheme: {
      type: Object,
    },
    statusItems: {
      type: Object,
      required: true,
    },
    selectedItem: {
      type: Object,
      required: true,
    },
    extendedCustomAwayReasons: {
      type: Object,
      required: true,
    },
    hasConfigForCustomMaxSize: {
      type: Boolean,
      default: false,
    },
    customReasonsOpen: {
      type: Boolean,
      default: false,
    },
    toggleCustomReasons: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      isFirstStatusAfterLogin: true,
      CONST,
      searchValue: "",
      itemChosen: null,
    }
  },
  computed: {
    customReasonsClass() {
      return {
        'top-level-menu-custom-reasons-list': true,
        'hide': !this.customReasonsOpen
      }
    }
  },
  methods: {
    filteredAwayStatusesList() {
      if(!this.searchValue){return this.extendedCustomAwayReasons}
      return Object.fromEntries(Object.entries(this.extendedCustomAwayReasons).filter(([key, value]) => value.text.toLowerCase().includes(this.searchValue.toLowerCase())));;
    },
    setFirstStatusToFalse() {
      if (this.isFirstStatusAfterLogin) {
        this.isFirstStatusAfterLogin = false;
      }
    },
    clicked() {
      this.$emit('clicked', this.statusItem);
    },
    itemClicked(item) {
      if (item.customReasonsAvailable) {
        this.toggleCustomReasons();
      } else {
        this.$emit('clicked', item);
        if (this.customReasonsOpen) {
          this.toggleCustomReasons();
        }
      }
    },
    chooseItem(item) {
      this.itemChosen = item;
      this.submitItem();
    },
    submitItem(){
      if(this.itemChosen){
        this.itemClicked(this.itemChosen);
      }
    },
    cancelButtonCustomReasons(){
      if (this.customReasonsOpen) {
        this.toggleCustomReasons();
      }
    },
    canExpand(item) {
      return (
        item.customReasonsAvailable &&
        ((this.isAwayStatus(item) && !this.hasConfigForCustomMaxSize) || !this.isAwayStatus(item))
      );
    },
    isAwayStatus({ value = '' }) {
      return value === CONST.DEFAULT_AGENT_STATUSES.away.value;
    },
  },
  components: {
    TopLevelMenuStatusItemView,
    TopLevelMenuCustomReasonItemView,
    leDialog,
  }
}
</script>

<style scoped>

</style>
