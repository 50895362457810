<template>
  <ul :data-lp-at="`list-item-${sectionIndex}`"
      :aria-labelledby="`${sectionName}-id`"
  >
    <li
      class="nav-list__header"
      role="presentation"
      :id="`${sectionName}-id`"
      :data-lp-at="`list-sub-header-${sectionIndex}`"
    >
      {{
        $translator.translate(
          'LEFramework.navbar.subCategory.' + sectionName
        )
      }}
    </li>
    <template
      v-for="(subCategory, subCategoryIndex) in subCategories"
      :key="subCategoryIndex"
    >
      <sub-category-item
        :subCategory="subCategory"
        :index="index"
        :sectionIndex="sectionIndex"
        :subCategoryIndex="subCategoryIndex"
        :selectedCategoryIndex="selectedCategoryIndex"
        :selectedSectionIndex="selectedSectionIndex"
        :selectedSubCategoryIndex="selectedSubCategoryIndex"
        :openCategoryIndex="openCategoryIndex"
        :isCategoryOpen="isCategoryOpen"
        :categoryIndex="categoryIndex"
        @requestSwitch="handleSwitchRequest"
      />
    </template>
  </ul>
</template>
<script>
import SubCategoryItem from './SubCategoryItem.vue';
export default {
  name: 'SubCategoryList',
  props: {
    subCategories: Array(Object),
    name: String,
    sectionIndex: Number,
    index: Number,
    selectedCategoryIndex: Number,
    selectedSectionIndex: Number,
    selectedSubCategoryIndex: Number,
    openCategoryIndex: Number,
    isCategoryOpen: Boolean,
    categoryIndex: Number
  },
  components: {
    SubCategoryItem
  },
  computed: {
    sectionName() {
      return this.name.toLowerCase();
    }
  },
  methods: {
    handleSwitchRequest(payload) {
      this.$emit('requestSwitch', {
        ...payload,
        index: this.index,
        sectionIndex: this.sectionIndex
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../../../assets/scss/navigation_bar';
</style>
