define(function (require) {
    "use strict";

    var BaseCollection = require("collections/BaseCollection");
    var SoftwareModel = require("models/entities/SoftwareModel");
    var ApiResources = require("assets/data/apiResources.json");
    // var marketplaceData = require('manager-assets/data/marketplace/marketplace.json');
    var NAME = "SoftwaresCollection";

    var SoftwaresCollection = BaseCollection.extend({
        model: SoftwareModel,
        name: NAME,
        resource: ApiResources.Internal.Software,
        // sync: function (method, collection, options) {
        //     options.success(marketplaceData);
        // },
        findSoftwareModelById: function(softwareId){
            var result = this.find(function(softwareModel){
                return softwareModel.getSoftwareId() === softwareId;
            });
            return result;
        }
    });

    SoftwaresCollection.NAME = NAME;

    return SoftwaresCollection;
});
