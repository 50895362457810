<template>
  <div
    aria-label="main"
    role="navigation"
    :class="{
      'navigation-drawer': true,
      'nav-drawer-open': anchor || hover,
      'hovered': hover && isHoverTransition,
      'anchor-animation': !isHoverTransition
    }"
    @mouseleave="handleMouseLeave"
    @blur="handleMouseLeave"
  >
    <div class="company-logo-container">
      <div
        role="button"
        aria-label="toggle navigation drawer"
        :aria-expanded="nvabarAriaExpanded"
        tabindex="0"
        class="menu-wrapper"
        @click="anchorMenu({ isNavbarToggleButton: true })"
        @keydown.enter.space="anchorMenu({ isNavbarToggleButton: true })"
      >
        <div
          v-if="!anchor"
          class="nb-s-icon s-menu"
        ></div>
        <div
          v-if="anchor"
          class="nb-s-icon s-close"
        ></div>
      </div>
      <transition name="fade">
        <le-logo v-if="anchor ? anchor : hover" />
      </transition>
    </div>

    <PersonaSwitcher
      :is-nav-bar-open="anchor ? anchor : hover"
      @toggle-menu="anchorMenu"
    />
  </div>
</template>

<script>
import PersonaSwitcher from './PersonaSwitcher';
import LeLogo from './LeLogo';

import { sessionManager } from 'vue-infra';

export default {
  name: 'TopBar',
  components: {
    PersonaSwitcher,
    LeLogo,
  },
  data() {
    return {
      hover: false,
      anchor: false,
      isNavBarClosed: true,
      hasPersonalization: false,
      lastFocusedElement: null,
      isHoverTransition: false,
    };
  },
  computed: {
    nvabarAriaExpanded() {
      return this.anchor || this.hover ? 'true' : 'false';
    }
  },
  created() {
    this.hasPersonalization = sessionManager.hasPersona('customizer');
  },
  mounted() {
    document.addEventListener('keyup', this.handleKeyUp);
    document.addEventListener('focus', function(event) {
      this.lastFocusedElement = event.target;
    }, true);
  },
  beforeUnmount() {
    document.removeEventListener('keyup', this.handleKeyUp);
  },
  methods: {
    handleMouseLeave() {
      this.isHoverTransition = true;
      this.hover = false;
    },
    handleKeyUp(event) {
      const ESC_KEY_CODE = 27;
      if (event.keyCode === ESC_KEY_CODE) {
        if (this.anchor) {
          this.anchor = false;
          this.isHoverTransition = false;
        }
        this.goBackToLastFocusedElement();
      }
    },
    anchorMenu({ isAccessibilityUser, isNavbarToggleButton, categoryClick }) {
      if (isAccessibilityUser && !isNavbarToggleButton) {
        // If accessibility user allow him to open menu on icon click/enter or space keys press, will be closed only via x icon
        if (!this.anchor) {
          this.anchor = true;
        }
      } else {
        if (!categoryClick) {
          this.anchor = !this.anchor;
        }
      }
      if (categoryClick) {
        this.hover = true;
      } else {
        this.hover = false;
      }
      this.isHoverTransition = false;
    },
    goBackToLastFocusedElement() {
      if (this.lastFocusedElement) {
        this.lastFocusedElement.focus();
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@import "../../../assets/scss/global";
@import "../../../assets/scss/mixins";

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.hovered {
  will-change: width;
  transition: width .35s;
  transition-timing-function: ease-out;
}

.anchor-animation {
  will-change: width;
  transition: width .3s;
  transition-timing-function: ease-out;
}

.navigation-drawer {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  width: $user-bar-width;

  @include themify {
    border-right: 1px solid getColor('nav-bar-border-color', 'framework');
    background-color: getColor('s-nav-bar-background', 'framework');
  }

  .company-logo-container {
    display: flex;
    align-items: center;
    padding: 0 16px;
    margin: 12px 0;

    .menu-wrapper {
      width: 48px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      cursor: pointer;
      border-radius: 6px;

      &:focus {
        @include themify(null, 'show-a11y-focus-outline') {
          background-color: getColor('s-nav-bar-item-hover', 'framework');
        }
      }

      &:hover {
        @include themify {
          background-color: getColor('s-nav-bar-item-hover', 'framework');
        }
      }
    }

    .nb-s-icon {
      width: 24px;
      height: 24px;
    }

    .nb-s-icon-logo {
      margin-left: 8px;
      width: $live-engage-logo-width;
      height: $live-engage-logo-height;
    }

    $miniLogo: s-logo-mini;
    $icons: s-menu, s-close;
    @include add-icons-from-list($miniLogo, 0, 0, false);
    @include add-icons-from-list($icons);


    .s-close{
      position: relative;
      left: 5px;
      top: 5px;
    }

  }
}

.nav-drawer-open {
  width: $user-bar-open-width;
}
</style>
