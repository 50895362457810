<template>
  <div>
    <skeleton
      v-if="isLoading"
      :count='6'
    />
    <ul
      v-if="!isLoading"
      class="nav-list"
    >
      <template
        v-for="(value, name, index) in generateSwitcherItems"
        :key="`${value.languageKey}`"
      >
        <template v-if="value.isPinnedApp">
          <li :key="'pinned' + index">
            <div class="list-categories">
              <nav-bar-pinned-app
                :value="value"
                :isNavBarOpen="isNavBarOpen"
                :name="name"
                :isCategoryOpen="isCategoryOpen"
                :index="index"
                :selectedCategoryIndex="selectedCategoryIndex"
                :openCategoryIndex="openCategoryIndex"
                @requestSwitch="onSwitchItem"
              />
            </div>

            <nav-bar-category-label
              :isNavBarOpen="isNavBarOpen"
              :app="value" :index="index"
              :selectedCategoryIndex="selectedCategoryIndex"
            />
          </li>
        </template>

      </template>
      <nav-bar-category-divider/>
      <template
        v-for="(value, name, index) in generateSwitcherItems"
        :key="`${value.languageKey}`"
      >
        <template v-if="!value.isPinnedApp">
          <li :key="'category' + index">
            <div class="list-categories">
              <div
                :aria-expanded="isNavBarOpen && openCategoryIndex === index && isCategoryOpen"
                role="button"
                tabindex="0"
                :aria-labelledby="`${value.languageKey}-id ${value.languageKey}-number-of-conversations-id`"
                :data-lp-at="`${value.languageKey}`"
                :class="{
                'nav-list__item': true,
                'nav-list__item-selected': selectedCategoryIndex === index,
                'nav-list__item-open': openCategoryIndex === index && isCategoryOpen
               }"
                @keydown.enter.space="onCategoryClick(index)"
                @click="onCategoryClick(index)"
              >
                <nav-bar-category-icon :icon="value.icon" :selectedCategoryIndex="selectedCategoryIndex" :index="index" />
                <nav-bar-title :index="index" :languageKey="value.languageKey" />
                <notification-bubble
                  v-show="value.notification && value.notification.count > 0"
                  :notification="value.notification"
                  :custom-id="`${value.languageKey}-number-of-conversations-id`"
                  :inline="isNavBarOpen"
                />
                <nav-bar-drop-down-icon :openCategoryIndex="openCategoryIndex" :index="index" :isCategoryOpen="isCategoryOpen" :animate="animate" />
              </div>
              <transition
                :name="`${animate ? 'accordion' : ''}`"
                @enter="start"
                @after-enter="end"
                @before-leave="start"
                @after-leave="end"
              >
                <ul
                  v-show="openCategoryIndex === index && isCategoryOpen && isNavBarOpen"
                  :data-lp-at="`list-${index}`"
                  role="presentation"
                  :class="`nav-list nav-list-nested`">
                  <template
                    v-for="(subCategories, sectionName, sectionIndex) in value.apps"
                    :key="`list-item-${sectionIndex}`"
                  >
                    <div class="list-categories">
                      <sub-category-list
                        :subCategories="subCategories"
                        :name="sectionName"
                        :sectionIndex="sectionIndex"
                        :index="index"
                        :selectedCategoryIndex="selectedCategoryIndex"
                        :selectedSectionIndex="selectedSectionIndex"
                        :selectedSubCategoryIndex="selectedSubCategoryIndex"
                        :openCategoryIndex="openCategoryIndex"
                        :isCategoryOpen="isCategoryOpen"
                        @request-switch="onSwitchItem"
                      />
                    </div>
                  </template>
                </ul>
              </transition>
            </div>
            <nav-bar-category-label
              :isNavBarOpen="isNavBarOpen"
              :app="value" :index="index"
              :selectedCategoryIndex="selectedCategoryIndex"
            />
          </li>
        </template>

      </template>

      <transition name="slide-left">
        <keep-alive>
          <component
            :is="selectedApp"
            v-if="isVueApp"
            :key="$route.name"
          />
        </keep-alive>
      </transition>
    </ul>
  </div>
</template>

<script>
import { cloneDeep, isEmpty } from 'lodash';
import navigator from 'routers/navigator';
import LEConfig from 'assets/config/le-env-conf';
import registerModule from 'src/modules/registerModule';
import Settings from 'assets/data/codeTables/settings.json';
import {
  ACClient,
  BaseClient,
  environmentActionTypes,
  environmentGetterTypes,
  environmentStateKeys,
  gChannel,
  Logger,
  sessionActionTypes,
  sessionKeys,
  sessionManager,
  storeModuleTypes,
  Vuex,
} from 'vue-infra';
import ApiResources from '../../../assets/data/apiResources.json';
import urlResolver from 'urlResolver';
import Skeleton from '../simplification/Skeleton';
import NotificationBubble from './NotificationBubble';
import SubCategoryItem from "./navigation-bar/SubCategoryItem.vue";
import SubCategoryList from "./navigation-bar/SubCategoryList.vue";
import NavBarCategoryLabel from "./navigation-bar/NavBarCategoryLabel.vue";
import NavBarCategoryDivider from "./navigation-bar/NavBarCategoryDivider.vue";
import NavBarCategoryIcon from "./navigation-bar/NavBarCategoryIcon.vue";
import NavBarTitle from "./navigation-bar/NavBarTitle.vue";
import NavBarPinnedApp from "./navigation-bar/NavBarPinnedApp.vue";
import NavBarDropDownIcon from "./navigation-bar/NavBarDropDownIcon.vue";
import * as CONST from 'const';
import { userHasAccessibilityEnabled, validateRoles, validatePrivileges, validateACFeatures } from "../../utils/accessControlUtils";
import { leBubble } from "@liveperson/lp-vue-components";

let LocaleResolver = require('../../../assets/js/i18n/localeResolver.js');
const logger = Logger.getLogger('LiveEngage:PersonaSwitcher');

const ANALYTICS_BUILDER_NAVBAR_MODULE_NAME = 'analytics-builder';
const IS_CHAT_ENABLE_FEATURE='Common.Legacy_Chat';
const AGENT_WORKSPACE_FOR_CHAT='Agent Workspace For Chat';
const IS_ENFORCE_NEW_AGENT_WORKSPACE = 'LEUI.Enforce_New_Agent_Workspace';
const CONVERSATIONAL_AI_PERSONA = 'lp-le-conversational-ai';
const MANAGEMENT_CONSOLE = 'lp-le-management-console';
const QA_ENV = 'qa';
const LEGACY_AGENT_STATE_SETTING_ID = 'common.legacyAgentState';
const WEB_VISITORS_APP_DISPLAY_NAME = 'navbar.appDisplayName.webVisitors';
const WITH_NEW_AGENT_WORKSPACE = 'withNewAgentWorkspace';
const APP_MANAGEMENT_SERVICE_DOMAIN = 'appMgmtSvcDomain';
const LE_RB_MSTR_SERVICE_URI = 'leRBMstr';
const AGENT_WORKSPACE_DISPLAY_NAME = 'Agent Workspace';
const MANAGER_WORKSPACE_DISPLAY_NAME = 'Manager Workspace';


const getters = Vuex.mapGetters(storeModuleTypes.ENVIRONMENT, {
  modules: environmentGetterTypes.MODULES,
  routes: environmentGetterTypes.ROUTES,
  regions: environmentGetterTypes.REGIONS,
  validApps: environmentGetterTypes.VALID_APPS,
  personaNotification: environmentGetterTypes.PERSONA_NOTIFICATION,
  currentPersona: environmentStateKeys.CURRENT_PERSONA
});
const actions = Vuex.mapActions(storeModuleTypes.ENVIRONMENT, {
  setValue: sessionActionTypes.SET_VALUE,
  modulesUpdate: environmentActionTypes.MODULES_UPDATE,
  routesUpdate: environmentActionTypes.ROUTES_UPDATE,
  validAppsUpdate: environmentActionTypes.VALID_APPS_UPDATE,
  personaNotificationUpdate: environmentActionTypes.PERSONA_NOTIFICATION_UPDATE
});
const lastRoutes = {};

export default {
  props: ['isNavBarOpen'],
  components: {
    Skeleton,
    NotificationBubble,
    SubCategoryList,
    SubCategoryItem,
    NavBarCategoryLabel,
    NavBarCategoryDivider,
    NavBarCategoryIcon,
    NavBarTitle,
    NavBarDropDownIcon,
    leBubble,
    NavBarPinnedApp
  },
  created() {
    this.internalModuleNames = registerModule.resolveInternalModules().map(module => module.moduleName);
    const url = ApiResources.AccountConfig.SiteSettings.url;
    const siteSettingsUrl = window.LE.urlResolver.getUrl(url);
    this.baseSiteSettings = new ACClient({
      allowedMethods: ['get', 'post'],
      config: {
        baseURL: siteSettingsUrl,
        params: {
          context_type: Settings.CONTEXT_TYPE.ACCOUNT,
          context_cascading: true,
          v: ApiResources.AccountConfig.SiteSettings.version
        },
      },
    })
    this.getQuickLaunchApplications();
  },
  async mounted() {
    gChannel.register('personaSwitcher', 'switch', options => this.switchItem(options));
    const isFirstTimeSiteSetting = await this.baseSiteSettings.get(Settings.SETTING_ID.FIRST_TIME_MC);
    if (isFirstTimeSiteSetting && isFirstTimeSiteSetting.data && isFirstTimeSiteSetting.data.propertyValue) {
      this.isFirstTime = (isFirstTimeSiteSetting.data.propertyValue.value === "true");
    }
  },
  emits: ['toggle-menu'],
  data() {
    return {
      popoverOptions: {
        html: true,
        trigger: 'manual',
        content: '',
        placement: 'right',
        autoHide: false,
        delay: {
          show: 500,
          hide: 0
        },
        open: false,
        bubbleBaseClass: 'personalization-popover'
      },
      animate: true,
      selectedTheme: null,
      selectedItemPos: 0,
      isVueApp: false,
      baseSiteSettings: null,
      isFirstTime: false,
      quickLaunchApps: {},
      isCategoryOpen: false,
      openCategoryIndex: null,
      selectedCategoryIndex: null,
      selectedSectionIndex: null,
      selectedSubCategoryIndex: null,
      isLoading: true,
      appsURLsLocalStorageKey: `ql_apps_domains_${sessionManager.getAccountId()}_${sessionManager.getUserId()}`
    };
  },
  computed: {
    generateSwitcherItems() {
      let switcherItems = {};
      const navbarConfig = window.LE.getConfig().__navbarConfiguration;

      if (this.regions && this.routes.length > 0) {
        Object.keys(this.regions).forEach((moduleName) => {
          const region = this.regions[moduleName];
          // region.shouldLoadApp should be undefined (when legacy) || true (when new app)
          if (region.switcherControl &&
            this.validApps.indexOf(region.switcherRoute) > -1) {
            const switcherRoute = this.calculateSwitcherRoute(moduleName, region)
            const persona = region.persona || sessionKeys.PERSONA.GUEST;
            const notification = (this.personaNotification || {})[persona] || {};
            if (this.isFirstTime && moduleName === MANAGEMENT_CONSOLE) {
              notification.count = 1;
            }
            const hasNotification = (!Number.isNaN(notification.count) && notification.count > 0);
            const item = {
              type: persona,
              className: region.switcherControl || '',
              fontIconName: region.fontIconName || '',
              iconClassName: region.switcherIconClassName || '',
              relatedModuleName: moduleName || '',
              baseSwitcherRoute: region.switcherRoute,
              switcherIndex: region.switcherIndex || '',
              switcherRoute,
              itemTitle: this.getTitleForRegion(region) || '',
              ...(hasNotification ? { notification } : {})
            };
            switcherItems = { ...switcherItems, [moduleName]: item };
          }
        });
        this.renameAWForChat(navbarConfig);
        const quickLaunchApps = this.quickLaunchApps;
        const sections = this.buildSections(navbarConfig.categories, switcherItems, quickLaunchApps)
        const filteredCategories = this.filterCategoriesBasedOnCriteria(sections)
        return this.filterApplicationsDisplay(filteredCategories);
      } else {
        return [];
      }
    },
    modules: getters.modules,
    validApps: getters.validApps,
    regions: getters.regions,
    routes: getters.routes,
    personaNotification: getters.personaNotification,
    currentPersona: getters.currentPersona
  },
  watch: {
    '$route'(to, from) {
      this.prevPath = from.path;
      if (this.$route.meta && this.$route.meta.marrionetteModule) {
        this.selectedApp = `${this.$route.meta.marrionetteModule}ChildProxy`;
        this.isVueApp = true;
      } else {
        this.isVueApp = false;
      }
    },
    isNavBarOpen() {
      if (this.isNavBarOpen) {
        logger.track("NAV | Open | Click");
      }
      this.animate = false;
    }
  },
  methods: {
    buildSections(categories, switcherItems, quickLaunchApps) {
      return Object.entries(categories).reduce((result, [categoryName, categoryObject]) => {
        if (categoryObject && !categoryObject.apps) {
          return { ...result, [categoryName]: categoryObject };
        }

        let isPinnedApp = false;
        if(categoryObject.apps[0].appDisplayName === AGENT_WORKSPACE_DISPLAY_NAME || categoryObject.apps[0].appDisplayName === MANAGER_WORKSPACE_DISPLAY_NAME) {
          isPinnedApp = true
        }

        const { categoryNotificationsCount, appsAsObject } = this.buildApps(
          categoryObject,
          categoryName,
          switcherItems,
          quickLaunchApps,
        );

        return {
          ...result,
          [categoryName]: {
            ...categoryObject,
            ...(categoryNotificationsCount > 0 ? { notification: { count: categoryNotificationsCount } } : {}),
            apps: appsAsObject,
            isPinnedApp
          },
        };
      }, {});
    },

    buildApps(categoryObject, categoryName, switcherItems, quickLaunchApps) {
      let categoryNotificationsCount = 0;
      const environment = window.LE.getConfig().lp_grid ? window.LE.getConfig().lp_grid : QA_ENV;
      const routes = this.routes;
      let newPersona;
      const appsAsObject = categoryObject.apps.reduce((subCategoriesResult, app) => {
        const {
          subCategory,
          moduleName,
          subModulePathName,
          subModuleChildPathName,
          subModulePath,
          iframeConfig,
          quickLaunchAppIDs,
          privileges,
          acFeatures,
          domain,
          addAccountIdQueryParam,
          roles,
          ...rest } = app;
        const subCategoryName = subCategory || 'directChild';
        let routeConfig = {};

        if (iframeConfig && this.modules) {
          const path = this.generateIframeRoute(iframeConfig, rest.appDisplayName, quickLaunchAppIDs, environment);
          if (path) {
            routeConfig.switcherRoute = path;
            routeConfig.isIframe = true;
          }
        } else if (rest.isExternal && domain) {
          routeConfig.switcherRoute = domain;
        } else if (rest.isExternal && quickLaunchAppIDs && !isEmpty(quickLaunchApps)) {
          const appId = quickLaunchAppIDs[environment];
          this.addReportBuilderIfNeeded(appId, moduleName);
          routeConfig.isExternal = true;
          let url = quickLaunchApps[appId];
          if (url && addAccountIdQueryParam) {
            url = this.appendBrandID(decodeURIComponent(url));
          }
          routeConfig.switcherRoute = url;
        } else if (subModulePathName) {
          routeConfig = this.getSubModuleRoute(subModulePathName, routes, subModuleChildPathName, moduleName, switcherItems)
        } else if (subModulePath) {
          routeConfig.switcherRoute = subModulePath;
        } else {
          routeConfig = switcherItems[moduleName];
        }
        routeConfig = {
          ...routeConfig,
          categoryName,
          roles,
          privileges,
          acFeatures,
          fontIconName: categoryObject.icon
        };


        if (routeConfig.notification && routeConfig.notification.count > 0) {
          categoryNotificationsCount += routeConfig.notification.count;
        }

        if (this.$route.path.indexOf(
            routeConfig.switcherRoute) === 0 && !newPersona &&
          (!this.currentPersona || (this.currentPersona && this.currentPersona.relatedModuleName !== moduleName))) {
          newPersona = routeConfig;
          this.setValue({ attribute: environmentStateKeys.CURRENT_PERSONA, value: routeConfig })
        }

        return {
          ...subCategoriesResult,
          [subCategoryName]: [...(subCategoriesResult[subCategoryName] || []), { ...rest, routeConfig }],
        };
      }, {});

      return { categoryNotificationsCount, appsAsObject };
    },

    getSubModuleRoute(subModulePathName, routes, subModuleChildPathName, moduleName, switcherItems) {
      const currentRoute = routes.find(route => route.name === subModulePathName);
      if (currentRoute) {
        const { path, children } = currentRoute || {};
        const switcherItem = cloneDeep(switcherItems[moduleName]);
        let childPath = '';
        if (subModuleChildPathName && children && children.length > 0) {
          const child = children.find(child => child.name === subModuleChildPathName);
          const { path } = child || {};
          if (path) {
            childPath = `/${path}`;
          }
        }
        switcherItem.switcherRoute = path + childPath;
        return switcherItem;
      }
    },

    renameAWForChat(navbarConfig) {
      const appIndex = navbarConfig.categories.Engage.apps.findIndex(app => app.appDisplayName === AGENT_WORKSPACE_FOR_CHAT);
      if (LEConfig.__isAgentStateIsDecoupleFromDenver && (sessionManager.getAccountSettingValueByID(LEGACY_AGENT_STATE_SETTING_ID) == 'false')) {
        if (sessionManager.getFeaturePropertyState(IS_ENFORCE_NEW_AGENT_WORKSPACE) && !sessionManager.getFeaturePropertyState(IS_CHAT_ENABLE_FEATURE)) {
          if (appIndex >= 0) {
            navbarConfig.categories.Engage.apps[appIndex].language.appDisplayName = WEB_VISITORS_APP_DISPLAY_NAME;
          }
        }
      } else if (LEConfig.__isHidingChat) {
        if (sessionManager.getFeaturePropertyState(IS_ENFORCE_NEW_AGENT_WORKSPACE) && !sessionManager.getFeaturePropertyState(IS_CHAT_ENABLE_FEATURE)) {
          if (appIndex >= 0) {
            navbarConfig.categories.Engage.apps[appIndex].language.appDisplayName = WEB_VISITORS_APP_DISPLAY_NAME;
          }
        }
      }
    },

    getTitleForRegion(region) {
      let title;
      if (region.config.switcher && region.config.switcher.titleTranslationKey) {
        // first check if the key exist in the application dictionary
        title = this.$t(`${region.config.routePrefix}:${region.config.switcher.titleTranslationKey}`);
        if (title === region.config.switcher.titleTranslationKey) {
          // first check if the key exist in the application dictionary
          title = this.$translator.translate(sessionKeys.PERSONA_TITLE[region.persona]);
        }
      } else {
        title = this.$translator.translate(sessionKeys.PERSONA_TITLE[region.persona]);
      }
      if (this.validApps.indexOf('aw') > -1 && this.$translator.hasTranslationKey(
        LocaleResolver.getDefaultLocale(),
        `${sessionKeys.PERSONA_TITLE[region.persona]}.${WITH_NEW_AGENT_WORKSPACE}`)) {
        title = this.$translator.translate(`${sessionKeys.PERSONA_TITLE[region.persona]}.${WITH_NEW_AGENT_WORKSPACE}`);
      }
      return title;
    },

    calculateSwitcherRoute(moduleName, region) {
      const internalModuleNames = registerModule.resolveInternalModules().map(module => module.moduleName);
      const moduleDefaultRoute = navigator.getDefaultRoute(moduleName);
      let switcherRoute;

      if (moduleDefaultRoute) {
        switcherRoute = `/${region.switcherRoute}/${moduleDefaultRoute}`;
      } else {
        if (internalModuleNames.includes(moduleName)) {
          switcherRoute = LEConfig.defaultRoute;
        } else {
          switcherRoute = `/${region.switcherRoute}`;
        }
      }

      return switcherRoute;
    },

    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    },
    appendBrandID(entryURI) {
      return entryURI.indexOf(
        '?') !== -1 ? entryURI + "&accountId=" + sessionManager.getAccountId() : entryURI + "?accountId=" + sessionManager.getAccountId();
    },
    async getQuickLaunchApplications() {
      const domain = sessionManager.getCsdsServiceURI(APP_MANAGEMENT_SERVICE_DOMAIN);
      let url = urlResolver.getUrl(ApiResources.AccountConfig.QuickLaunch.url, {
        appMgmtSvcDomain: domain,
        accountId: sessionManager.getAccountId(),
      });
      const client = new BaseClient({
        config: { baseURL: url },
        retryConfig: {
          retries: 5,
          retryDelay: retryCount => retryCount * 300,
          retryCondition: () => true,
        }
      });
      try {
        const res = await client.get('');
        this.isLoading = false;
        if (res && res.data) {
          this.quickLaunchApps = res.data.reduce((obj, { entry_uri, client_id }) => {
            return {
              ...obj,
              [client_id]: entry_uri
            };
          }, {});
        }

        if (this.quickLaunchApps && !isEmpty(this.quickLaunchApps)) {
          // Set to local storage for fallback
          window.localStorage.setItem(this.appsURLsLocalStorageKey, JSON.stringify(this.quickLaunchApps));
        }

      } catch (error) {
        this.quickLaunchApps = JSON.parse(window.localStorage.getItem(this.appsURLsLocalStorageKey));
        this.isLoading = false;
        logger.error(`Could not get quick launch applications, error: ${error}`);
        if (!this.quickLaunchApps || isEmpty(this.quickLaunchApps)) {
          LE.notifier.showError(this.$translator.translate('LEFramework.navbar.quickLaunchAPI.errorMessage'));
        }
      }
    },

    onCategoryClick(index) {
      this.animate = true;
      if (userHasAccessibilityEnabled()) {
        this.$emit('toggle-menu', { isAccessibilityUser: true } );
      } else {
        this.$emit('toggle-menu', { isAccessibilityUser: false, categoryClick: true } );
      }
      if (this.openCategoryIndex === index && this.isNavBarOpen) {
        this.isCategoryOpen = !this.isCategoryOpen;
      } else {
        this.isCategoryOpen = true;
      }
      this.openCategoryIndex = index;
    },

    onSwitchItem({routeConfig, subCategoryIndex, index, sectionIndex }) {
      this.switchItem({
        item: routeConfig,
        categoryIndex: index,
        sectionIndex: sectionIndex,
        subCategoryIndex: subCategoryIndex
      });
    },

    async switchItem({ item, switchedPersona, categoryIndex, sectionIndex, subCategoryIndex, backBrowser = true }) {
      if (switchedPersona) {
        item = this.findItemBy(switchedPersona);
      }

      if (item.switcherRoute === "/mc") {
        this.baseSiteSettings.patch(undefined, [{
          id: Settings.SETTING_ID.FIRST_TIME_MC,
          type: 2,
          propertyValue: { value: false },
        }], {
          revision: -1,
        }).catch(e => {
          logger.error(`Can not update site setting ${Settings.SETTING_ID.FIRST_TIME_MC} ,${e}`);
          this.isFirstTime = true;
        }).finally(() => {
          this.isFirstTime = false;
        })
      }

      // this.selectedItemPos = this.findItemInedexBy('relatedModuleName', item.relatedModuleName);
      // store last route for this module
      const currentRoute = this.$router.currentRoute.value
      if (currentRoute && this.currentPersona && this.currentPersona.relatedModuleName) {
        const currentPath = currentRoute.path;
        if (currentPath.indexOf(this.currentPersona.baseSwitcherRoute) === 1) {
          lastRoutes[this.currentPersona.relatedModuleName] = currentPath;
        }
      }

      let nextRoute = lastRoutes[item.relatedModuleName] ? lastRoutes[item.relatedModuleName] : item.switcherRoute;

      // Edge case for cai apps(routed from single cai loaded module)
      if (item &&
        item.switcherRoute &&
        item.relatedModuleName === CONVERSATIONAL_AI_PERSONA &&
        lastRoutes[item.relatedModuleName] !== item.switcherRoute) {
        nextRoute = item.switcherRoute;
      }

      if (backBrowser) {
        this.$router.push(nextRoute);
      } else {
        this.$router.replace(nextRoute);
      }
      if (nextRoute.indexOf(item.baseSwitcherRoute) === 1) {
        this.selectedCategoryIndex = categoryIndex;
        this.selectedSectionIndex = sectionIndex;
        this.selectedSubCategoryIndex = subCategoryIndex;
      }
    },

    findItemBy(value) {
      return Object.values(this.generateSwitcherItems)
        .map(({ apps }) => apps)
        .map((item) => Object.values(item))
        .flat(2)
        .map(({ routeConfig }) => routeConfig)
        .find(({ type }) => type === value)
    },

    filterCategoriesBasedOnCriteria(sections) {
      let filteredSections = {};
      Object.entries(sections).forEach(([sectionName, sectionData]) => {
        const filteredApps = {};
        Object.entries(sectionData.apps).forEach(([categoryName, apps]) => {
          const visibleApps = apps.filter(app => {
            let shouldDisplay = false;
            const { routeConfig } = app;
            if (routeConfig) {
              const { privileges, acFeatures, roles, switcherRoute } = routeConfig;
              if (switcherRoute) {
                shouldDisplay = validatePrivileges(privileges) &&
                  validateACFeatures(acFeatures) &&
                  validateRoles(roles);
              }
              else {
                if (switcherRoute) {
                  shouldDisplay = true;
                }
              }
            }
            return shouldDisplay;
          });
          if (visibleApps.length > 0) {
            filteredApps[categoryName] = visibleApps;
          }
        });
        if (Object.keys(filteredApps).length > 0) {
          filteredSections[sectionName] = { ...sectionData, apps: filteredApps };
        }
      });
      return filteredSections;
    },

    filterApplicationsDisplay(sections) {
      return Object.entries(sections).reduce((sectionRes, [sectionName, sectionData], categoryIndex) => {
        let routerConfig;
        const { apps: subCategories = {} } = sectionData;
        const filteredSubCategories = Object.entries(subCategories)
          .reduce((categoryRes, [subCategoryName, apps], sectionIndex) => {
            const filteredApps = apps.filter((app, subCategoryIndex) => {
              let shouldDisplay = false;
              const { routeConfig } = app;
              routerConfig = routeConfig
              if (routeConfig) {
                const { privileges, acFeatures, roles, switcherRoute } = routeConfig;
                if (switcherRoute) {
                  shouldDisplay = validatePrivileges(privileges) &&
                    validateACFeatures(acFeatures) &&
                    validateRoles(roles);
                }
                else {
                  if (switcherRoute) {
                    shouldDisplay = true;
                  }
                }
              }
              return shouldDisplay;
            });

            const subCategoryIndex = filteredApps.findIndex(
              ({ routeConfig }) => this.$route.path.indexOf(routeConfig.switcherRoute) === 0);
            if (subCategoryIndex > -1) {
              this.selectedCategoryIndex = categoryIndex;
              this.selectedSectionIndex = sectionIndex;
              this.selectedSubCategoryIndex = subCategoryIndex;
            }

            if(sectionName === "AgentWorkspace" || sectionName === "ManagerWorkspace") {
              sectionData.pinnedRoute = routerConfig;
            }

            return { ...categoryRes, ...(filteredApps.length > 0 ? { [subCategoryName]: filteredApps } : {}) };
          }, {});

        return {
          ...sectionRes, ...(!isEmpty(filteredSubCategories) ? {
            [sectionName]: {
              ...sectionData,
              apps: filteredSubCategories
            }
          } : {})
        };
      }, {});
    },

    generateIframeSource(source, quickLaunchAppIDs, environment) {
      let sourceStr = '';
      if (source) {
        sourceStr = source[environment];
      }
      if (!sourceStr && quickLaunchAppIDs) {
        const appId = quickLaunchAppIDs[environment];
        sourceStr = this.quickLaunchApps[appId];
      }
      return sourceStr;
    },

    generateIframeRoute(iframeConfig, appDisplayName, quickLaunchAppIDs, environment) {
      const { source, name, path, personas } = iframeConfig;
      let evaluatedSource = this.generateIframeSource(source, quickLaunchAppIDs, environment);
      if (evaluatedSource && name && path && personas) {
        const wasRouteAdded = this.routes.some(route => route.name === name);
        const routeObj = {
          name,
          path,
          meta: {
            isIframe: true,
            source: evaluatedSource,
            appDisplayName
          }
        }
        if (!wasRouteAdded && this.modules) {
          this.$router.addRoutes([routeObj]);
          this.routesUpdate([routeObj]);
          this.validAppsUpdate(name);
          this.modules.moduleIds[name] = name;
          const moduleName = {
            list: [name],
            moduleConfig: {
              "id": name,
              "moduleName": name,
              "routePrefix": name,
              "personaMapping": name,
              personas,
              switcher: {
                control: "switcher-item-" + name,
                index: Math.floor(Math.random() * (999999 - 10000 + 1) + 10000),
              },
              "external": true,
              "regionId": name + "-iframe"
            },
            moduleMapping: name,
            routePrefix: name,
            registered: true
          }
          this.modules.moduleNames[name] = moduleName;
          this.modules.personaMap[name] = name;
          this.modules.prefixMap[name] = name;
          this.modulesUpdate(this.modules);
        }
        return path;
      }
    },
    addReportBuilderIfNeeded(appId, moduleName) {
      if (moduleName === ANALYTICS_BUILDER_NAVBAR_MODULE_NAME && this.showReportBuilder()) {
        this.quickLaunchApps[appId] = this.buildReportBuilderUrl();
      }
    },
    showReportBuilder() {
      const hasAccessFeature = sessionManager.hasReportBuilderAccessFeature();
      const config = sessionManager.getConfig();
      const isLPA = ( config && config.isLPA ) || false;
      const hasNonLpaAccess = sessionManager.hasReportBuilderNonLpaAccessFeature();
      const hasRequiredPrivileges = sessionManager.hasPrivilege([
        sessionManager.PRIVILEGES.VIEW_REPORTS_IN_REPORT_BUILDER,
        sessionManager.PRIVILEGES.CUSTOMIZE_REPORTS_IN_REPORT_BUILDER,
        sessionManager.PRIVILEGES.SHARE_REPORTS_IN_REPORT_BUILDER,
      ], true);

      return hasAccessFeature && ( isLPA || ( hasNonLpaAccess && hasRequiredPrivileges ));
    },
    buildReportBuilderUrl() {
      const domain = sessionManager.getCsdsServiceURI(LE_RB_MSTR_SERVICE_URI);
      const url = urlResolver.getUrl(ApiResources.DataMarketer.ReportBuilderQuickLaunch.url, {
        leRBMstr: domain,
        accountId: sessionManager.getAccountId(),
      });
      return url;
    },

    setValue: actions.setValue,
    modulesUpdate: actions.modulesUpdate,
    routesUpdate: actions.routesUpdate,
    validAppsUpdate: actions.validAppsUpdate
  }
};

</script>

<style lang='scss' scoped>
@import "../../../assets/scss/navigation_bar";

.list-categories {
  padding: 0 12px;
}
</style>
